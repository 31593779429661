import React from 'react';

const { Tooltip } = require('@mui/material');
const { PickersDay } = require('@mui/x-date-pickers');

export default function CustomizedDate(props) {
	const { disabled, bloomspread, clouds, bloomlevel, planetindex, hasplanet, showsensorsdata, sensordata, lowvisibility } = props.data;
	const { selectedLocation } = props;
	let tooltipTitle;
	// const sentintel = 'Sentinel 3:';
	const planet = 'Planet:';

	const [open, setOpen] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
		setTimeout(() => {
			handleClose();
		}, 5000);
	};

	// let planetTooltip;

	if (planetindex > -1) {
		// planetTooltip = (
		// 	<span>
		// 		{' '}
		// 		<br /> {planet} <br /> {'Imagery available'}{' '}
		// 	</span>
		// );
	} else {
		// planetTooltip = (
		// 	<span>
		// 		{' '}
		// 		<br /> {planet} <br /> {'Imagery not available'}{' '}
		// 	</span>
		// );
	}

	if (!hasplanet) {
		// planetTooltip = '';
	}

	if (isNaN(clouds) && isNaN(bloomlevel) && isNaN(bloomspread)) {
		if (showsensorsdata) {
			tooltipTitle = (
				<span>
					{lowvisibility ? 'Day with low visibility' : ''}
					{lowvisibility ? <br /> : ''}
					{'Sensors'}
					<br />
					{`Chl: ${sensordata.Chl}`}
					<br />
					{`PC: ${sensordata.PC}`}
					<br />
					{`Ri: ${sensordata.Ri}`}
				</span>
			);
		} else {
			if (lowvisibility) {
				tooltipTitle = <span>{'Day with low visibility'}</span>;
			} else {
				tooltipTitle = (
					<span>
						{planet}
						<br />
						{'No data available.'}
						<br />
						{planetindex > -1 ? 'Imagery available' : 'No Satellite imagery available.'}
						{/* {planetTooltip} */}
					</span>
				);
			}
		}
	} else if (clouds || bloomlevel) {
		tooltipTitle = (
			<span>
				{lowvisibility ? 'Day with low visibility' : ''}
				{lowvisibility ? <br /> : ''}
				{planet}
				<br />
				{selectedLocation?.saltWater ? 'Bloom Level (max)' : 'Bloom Level'} :{(Math.round(bloomlevel * 100) / 100).toFixed(2)}
				{/* <br /> */}
				{/* Bloom Spread :{(Math.round(bloomspread * 100) / 100).toFixed(2)} */}
				{/* {'%'} */}
				<br />
				Clouds: {(Math.round(clouds * 100) / 100).toFixed(2)}
				{'%'}
				{/* {planetTooltip} */}
				{showsensorsdata ? <br /> : ''}
				{showsensorsdata ? 'Sensors' : ''}
				{showsensorsdata ? <br /> : ''}
				{showsensorsdata ? `Chl: ${sensordata.Chl}` : ''}
				{showsensorsdata ? <br /> : ''}
				{showsensorsdata ? `PC: ${sensordata.PC}` : ''}
				{showsensorsdata ? <br /> : ''}
				{showsensorsdata ? `Ri: ${sensordata.Ri}` : ''}
			</span>
		);
	}

	if (!tooltipTitle) {
		if (showsensorsdata) {
			tooltipTitle = (
				<span>
					{lowvisibility ? 'Day with low visibility' : ''}
					{lowvisibility ? <br /> : ''}
					{'Sensors'}
					<br />
					{`Chl: ${sensordata.Chl}`}
					<br />
					{`PC: ${sensordata.PC}`}
					<br />
					{`Ri: ${sensordata.Ri}`}
				</span>
			);
		} else {
			if (lowvisibility) {
				tooltipTitle = <span>{'Day with low visibility'}</span>;
			} else {
				tooltipTitle = (
					<span>
						{planet}
						<br />
						{'No data available.'}
						<br />
						{planetindex > -1 ? 'Imagery available' : 'No Satellite imagery available.'}
						{/* {planetTooltip} */}
					</span>
				);
			}
		}
	}

	return (
		<Tooltip title={tooltipTitle} placement="top-start" open={open} onClose={handleClose} onOpen={handleOpen}>
			<span>
				<PickersDay {...props.data} style={disabled ? { textDecoration: 'line-through' } : { color: props.data.selected ? 'white' : 'rgba(0, 0, 0, 0.75)' }} />
			</span>
		</Tooltip>
	);
}
