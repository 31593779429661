export const SENSORS_FIRST_CHARTS_LABELS = [
	{
		title: 'Chl-a',
		color: '#5EA797',
	},
	{
		title: 'PC',
		color: '#F1CD70',
	},
];

export const SENSORS_SECOND_CHARTS_LABELS = [
	{
		title: 'Ri',
		color: '#18A0FB',
	},
];

export const SENSORS_THIRD_CHART_LABELS = [
	{
		key: 'Turbidity',
		value: 'Turbidity',
	},
	{
		key: 'DO',
		value: 'DO',
	},
	{
		key: 'pH',
		value: 'PH',
	},
	{
		key: 'Water Temp',
		value: 'WaterTemp',
	},
	{
		key: 'Solar Light',
		value: 'solarlight',
	},
];

//COLOURS ARTS
export const TRENDS_LINES_ARTS_COLOURS = {
	Chl: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
	PC: {
		0: '#745A16',
		1: '#FFB764',
		2: '#AC8B35',
		3: '#D6B255',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#FFB764',
	},
};

export const TRENDS_LINES_ARTS_COLOURS_RI = {
	Ri: {
		0: '#0C71B4',
		1: '#18A0FB',
		2: '#9DD3F7',
		3: '#B7E2FF',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
};

export const TRENDS_LINES_ARTS_COLOURS_THIRD_CHART = {
	Turbidity: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
	DO: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
	PH: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
	WaterTemp: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
	solarlight: {
		0: '#1B6C5A',
		1: '#3B8575',
		2: '#5EA797',
		3: '#053D31',
		4: '#',
		5: '#',
		6: '#',
		7: '#',
		Average: '#5EA797',
	},
};

//CHART DESIGN RULES.
export const ART_CHART_LAST_DATE_FILL_COLOUR = 'rgba(0,0,0,0.5)';
export const ART_CHART_DATE_FILL_COLOUR = 'transparent';
export const ART_CHART_LABELS_FONT_SIZE = '10px';
export const ART_CHART_LABELS_FONT_FAMILY = 'Inter';
