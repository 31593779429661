import { format, sub as subDays } from 'date-fns';
import { Constants } from '../components/Constants';

const getRandom = (max) => {
	return Math.floor(Math.random() * max);
};

export const getSensorsAvailableDates = () => {
	let data = [];

	for (let i = 500; i > 0; i--) {
		let date = new Date(subDays(new Date(), { days: i }));
		date = format(date, Constants.DATE_FORMATS.SHORT_DATE_FORMAT);
		data.unshift(date.toString());
	}

	return data;
};

const generateArtDatapoints = () => {
	let data = [];

	for (let i = 500; i > 0; i--) {
		let date = new Date(subDays(new Date(), { days: i }));

		if (i < 9) {
			for (let j = 1; j < 22; j++) {
				data.push({
					timestamp: new Date(date.setHours(j)),

					airTemp: getRandom(100),

					WaterTemp: getRandom(50),

					Chl: getRandom(100),

					solarlight: getRandom(100),

					PC: getRandom(100),

					sigStrength: '',

					Turbidity: getRandom(50),

					Ri: getRandom(100),

					battSOC: '', // batteryStatus requested, this is provided

					battVolts: '', // batteryStatus requested, this is provided

					PH: getRandom(100), // not provided

					DO: getRandom(100), // not provided

					enabled: true,

					brushError: '', //x

					codeVersion: '', //x

					fixOk: '', //x

					leakDetect: '', //x

					panelVolts: '', //x

					sigQuality: '', //x
				});
			}
		} else {
			data.push({
				timestamp: new Date(date),

				airTemp: getRandom(100),

				WaterTemp: getRandom(100),

				Chl: getRandom(100),

				solarlight: getRandom(100),

				PC: getRandom(100),

				sigStrength: '',

				Turbidity: getRandom(100),

				Ri: getRandom(100),

				battSOC: '', // batteryStatus requested, this is provided

				battVolts: '', // batteryStatus requested, this is provided

				PH: getRandom(100), // not provided

				DO: getRandom(100), // not provided

				enabled: true,

				brushError: '', //x

				codeVersion: '', //x

				fixOk: '', //x

				leakDetect: '', //x

				panelVolts: '', //x

				sigQuality: '', //x
			});
		}
	}
	return data;
};

export const artMockData = [
	{
		artId: '1111',

		locationsId: '',

		latitude: 30.144048204590487,

		longitude: -81.71945760390892,

		data: generateArtDatapoints(),
	},
	{
		artId: '22222',

		locationsId: '',

		latitude: 30.153251480152168,

		longitude: -81.70838544471196,

		data: generateArtDatapoints(),
	},

	{
		artId: '33333',

		locationsId: '',

		latitude: 30.12856325879072,

		longitude: -81.74132293453829,

		data: generateArtDatapoints(),
	},
	{
		artId: '444444',

		locationsId: '',

		latitude: 30.113185504749534,

		longitude: -81.75278959202717,

		data: generateArtDatapoints(),
	},
];
