import { sub as subDays, add as addDays, differenceInMonths, differenceInQuarters, differenceInWeeks } from 'date-fns';

export const getAvailableSensors = (data) => {
	if (!data || data.length === 0) return undefined;

	let sensors = [];
	let elm;

	data.forEach((element) => {
		elm = { artId: element.artId, latitude: element.latitude, longitude: element.longitude, selected: true };
		sensors.push(elm);
	});

	return sensors;
};

export const getArtsDataToDisplay = (artDataCopy, availableSensorsCopy, showAverageSensorsData, dateRange) => {
	if (!artDataCopy || !availableSensorsCopy) return null;

	// let result = [
	// 	{
	// 		data: [], //should include the data to render
	// 		paramName: '', //should include the param name to render for example chl, PC
	// 	},
	// ];

	//first chart relevant data
	let chlResult = [];
	let pcResult = [];

	//second chart relevant data
	let riResult = [];

	//third chart relevant data
	let turbidityResult = [];
	let doResult = [];
	let phResult = [];
	let waterTempResult = [];
	let solarLightResult = [];

	let result = [];
	let availableSensors = availableSensorsCopy.filter((el) => el.selected);
	if (!availableSensors || availableSensors.length === 0) return null;
	let artData = [];

	artDataCopy.forEach((el) => {
		//deep clone art data so data array will be saved as values instead of reference
		artData.push({ ...el, data: [...el.data] });
	});
	// filter availableSensors via time frame TODO

	availableSensors = filterSensorsDataViaTimeFrame([...availableSensors], dateRange, [...artData]);

	//first chart relevant params
	const chlParams = ['Chl'];
	const pcParams = ['PC'];

	//second chart relevant params
	const riParams = ['Ri'];

	//third chart relevant params
	const turbidityParams = ['Turbidity'];
	const doParams = ['DO'];
	const phParams = ['PH'];
	const waterTempParams = ['WaterTemp'];
	const solarLightParams = ['solarlight'];

	chlResult = getSensorsData(artData, availableSensors, chlParams);
	pcResult = getSensorsData(artData, availableSensors, pcParams);
	riResult = getSensorsData(artData, availableSensors, riParams);

	turbidityResult = getSensorsData(artData, availableSensors, turbidityParams);
	doResult = getSensorsData(artData, availableSensors, doParams);
	phResult = getSensorsData(artData, availableSensors, phParams);
	waterTempResult = getSensorsData(artData, availableSensors, waterTempParams);
	solarLightResult = getSensorsData(artData, availableSensors, solarLightParams);

	//if we want average, calculate averages else return values
	if (showAverageSensorsData) {
		//first chart
		chlResult = getAverages(chlResult, chlParams[0]);
		pcResult = getAverages(pcResult, pcParams[0]);
		//second chart
		riResult = getAverages(riResult, riParams[0]);
		//third chart
		turbidityResult = getAverages(turbidityResult, turbidityParams[0]);
		doResult = getAverages(doResult, doParams[0]);
		phResult = getAverages(phResult, phParams[0]);
		waterTempResult = getAverages(waterTempResult, waterTempParams[0]);
		solarLightResult = getAverages(solarLightResult, solarLightParams[0]);

		//first chart data
		if (chlResult && chlResult.length) {
			chlResult = [{ paramName: chlParams[0], data: [...chlResult] }];
			result = [...chlResult];
		}
		if (pcResult && pcResult.length) {
			pcResult = [{ paramName: pcParams[0], data: [...pcResult] }];
			result = [...result, ...pcResult];
		}
		//second chart data
		if (riResult && riResult.length) {
			riResult = [{ paramName: riParams[0], data: [...riResult] }];
		}
		//third chart data
		if (turbidityResult && turbidityResult.length) {
			turbidityResult = [{ paramName: turbidityParams[0], data: [...turbidityResult] }];
		}

		if (doResult && doResult.length) {
			doResult = [{ paramName: doParams[0], data: [...doResult] }];
		}

		if (phResult && phResult.length) {
			phResult = [{ paramName: phParams[0], data: [...phResult] }];
		}

		if (waterTempResult && waterTempResult.length) {
			waterTempResult = [{ paramName: waterTempParams[0], data: [...waterTempResult] }];
		}

		if (solarLightResult && solarLightResult.length) {
			solarLightResult = [{ paramName: solarLightParams[0], data: [...solarLightResult] }];
		}
	} else {
		if (chlResult && chlResult.length) {
			result = [...chlResult];
		}
		if (pcResult && pcResult.length) {
			result = [...result, ...pcResult];
		}
	}

	let thirdChartData = {};
	thirdChartData[`${turbidityParams[0]}`] = turbidityResult;
	thirdChartData[`${doParams[0]}`] = doResult;
	thirdChartData[`${phParams[0]}`] = phResult;
	thirdChartData[`${waterTempParams[0]}`] = waterTempResult;
	thirdChartData[`${solarLightParams[0]}`] = solarLightResult;

	return { firstChartData: result, secondChartData: riResult, thirdChartData: thirdChartData };
};

//build the data to be displayed for the sensors chart
export const getSensorsData = (artData, availableSensors, params) => {
	if (!artData || !availableSensors) return null;
	let element = {};
	let result = [];
	let helpArray = [];

	artData.forEach((el) => {
		if (availableSensors.findIndex((obj) => obj.artId === el.artId) >= 0) {
			params.forEach((param) => {
				if (el && el.data?.length) {
					el.data.forEach((obj) => {
						element = { date: new Date(obj.timestamp) };
						element[param] = obj[param];
						helpArray.unshift(element);
					});

					result.push({ paramName: param, data: helpArray, artId: el.artId });
					helpArray = [];
				}
			});
		}
	});
	return result;
};

export const getAverages = (data, key) => {
	if (!key || !data || !data.length) return null;
	let avergaes = [];
	const numberOfSensors = data.length;
	data.forEach((el) => {
		if (el && el.data?.length) {
			// loop over data
			el.data.forEach((obj, index) => {
				if (avergaes[index]) {
					if (avergaes[index][key]) {
						// if valid value add averaged value.
						avergaes[index][key] += obj[key] / numberOfSensors || 0;
					} else {
						if (!avergaes[index][key]) {
							avergaes[index][key] = obj[key] / numberOfSensors || 0; // init avergaed value.
						}
					}
				} else {
					//init avergaes object
					avergaes[index] = { ...obj };
					avergaes[index][key] = obj[key] / numberOfSensors || 0; // init avergaed value.
				}
			});
		}
	});

	return avergaes;
};

const filterSensorsDataViaTimeFrame = (availableSensorsCopy, dateRange, artData) => {
	let availableSensors = [...artData].filter((el) => availableSensorsCopy.findIndex((obj) => obj.artId === el.artId) >= 0);
	let result = [...availableSensors];
	let dateDelta,
		baseDate = availableSensors[0].data[availableSensors[0].data.length - 1]?.timestamp,
		thisYear = isNaN(Number(dateRange.id)) ? new Date().getFullYear() : Number(dateRange.id),
		lastYear,
		previousYear;
	let lastJan1 = new Date(lastYear, 0, 1);
	let finalData = [];

	availableSensors.forEach((sensorData, index) => {
		if (sensorData && sensorData.data && sensorData.data.length) {
			if (isNaN(dateRange?.id) && dateRange?.id !== 'YearToDate') {
				switch (dateRange.id) {
					case 'PastWeek':
						result[index].data = sensorData.data.filter((_data) => _data.timestamp > subDays(baseDate, { weeks: 1 }));
						break;
					case 'PastMonth':
						result[index].data = sensorData.data.filter((_data) => _data.timestamp > subDays(baseDate, { months: 1 }));
						result[index].data = convertHourlyToAverageDailyData([...result[index].data]);
						break;
					case 'Past3Months':
						result[index].data = sensorData.data.filter((_data) => _data.timestamp > subDays(baseDate, { months: 3 }));
						result[index].data = convertHourlyToAverageDailyData([...result[index].data]);
						break;

					case 'Past6Months':
						thisYear = Number(dateRange.id);
						lastYear = thisYear - 1;
						lastJan1 = new Date(lastYear, 0, 1);

						result[index].data = sensorData.data.filter((_data) => _data.timestamp >= subDays(baseDate, { months: 6 }) && _data.timestamp <= baseDate);
						result[index].data = convertHourlyToAverageDailyData([...result[index].data]);

						break;

					case 'PastYear':
						thisYear = Number(dateRange.id);
						lastYear = thisYear - 1;
						lastJan1 = new Date(lastYear, 0, 1);

						result[index].data = sensorData.data.filter((_data) => _data.timestamp >= subDays(baseDate, { years: 1 }) && _data.timestamp <= baseDate);
						result[index].data = convertHourlyToAverageDailyData([...result[index].data]);

						break;
					case 'Monthly':
						// rollup each month's data into a single average value by month
						finalData = []; //init final data
						dateDelta = differenceInMonths(sensorData.data[sensorData.data.length - 1].timestamp, sensorData.data[0].timestamp);
						for (let month = 1; month <= dateDelta; month++) {
							let monthData = sensorData.data.filter((_data) => _data.timestamp >= subDays(baseDate, { months: month }) && _data.timestamp <= subDays(baseDate, { months: month - 1 }));

							monthData = convertHourlyToAverageDailyData([...monthData]);

							const value = rollupData(monthData);
							finalData.unshift(value);
						}
						result[index].data = finalData;

						break;
					case 'Quarterly':
						// rollup each quarter's data into a single average value by quarter
						dateDelta = differenceInQuarters(sensorData.data[sensorData.data.length - 1].timestamp, sensorData.data[0].timestamp);
						finalData = []; //init final data

						for (let quarter = 1; quarter <= dateDelta; quarter++) {
							const values = convertHourlyToAverageDailyData([...sensorData.data]);
							let quarterData = values.filter((_data) => _data.timestamp >= subDays(baseDate, { months: quarter * 3 }) && _data.timestamp <= subDays(baseDate, { months: quarter * 3 - 3 }));
							let value = rollupData(quarterData);

							finalData.unshift(value);

							if (quarter < dateDelta) {
								quarterData = sensorData.data.filter((_data) => _data.timestamp >= subDays(baseDate, { months: quarter * 3 + 3 }) && _data.timestamp <= subDays(baseDate, { months: quarter * 3 }));
							}
						}
						result[index].data = finalData;

						break;
					case 'Yearly':
						dateDelta = differenceInWeeks(sensorData.data[sensorData.data.length - 1].timestamp, sensorData.data[0].timestamp);
						finalData = []; //init final data

						for (let week = 1; week <= dateDelta; week++) {
							let monthData = sensorData.data.filter((_data) => _data.timestamp >= subDays(baseDate, { weeks: week }) && _data.timestamp <= subDays(baseDate, { weeks: week - 1 }));
							if (monthData && monthData[0]) {
								monthData = convertHourlyToAverageDailyData([...monthData]);
								let value = rollupData(monthData);

								finalData.unshift(value);
							}

							if (week < dateDelta) {
								monthData = sensorData.data.filter((_data) => _data.datimestampte >= subDays(baseDate, { weeks: week + 1 }) && _data.timestamp <= subDays(baseDate, { weeks: week }));
								if (monthData && monthData[0]) {
								}
							}
						}
						result[index].data = finalData;

						break;

					default:
						// rollup data by week into average values
						dateDelta = 52;
						lastYear = thisYear; // use lastYear as the setting for the selected year
						previousYear = lastYear - 1;
						finalData = []; //init final data

						let previousJan1 = new Date(previousYear, 0, 1);

						for (let week = 1; week <= dateDelta; week++) {
							// eslint-disable-next-line no-loop-func
							let weekData = sensorData.data.filter((_data) => _data.timestamp.getFullYear() >= lastYear && _data.timestamp.getFullYear() <= thisYear && _data.timestamp > addDays(lastJan1, { weeks: week - 1 }) && _data.timestamp <= addDays(lastJan1, { weeks: week }));
							weekData = convertHourlyToAverageDailyData([...weekData]);

							let value = rollupData(weekData);
							finalData.unshift(value);

							// result[index].data.push(value);

							if (week > 1) {
								// eslint-disable-next-line no-loop-func
								weekData = sensorData.data.filter((_data) => _data.timestamp.getFullYear() >= previousYear && _data.date.getFullYear() <= lastYear && _data.timestamp > addDays(previousJan1, { weeks: week - 1 }) && _data.timestamp <= addDays(previousJan1, { weeks: week }));

								// period.previous.push(rollupData(weekData));
							}
						}
						result[index].data = finalData;

						break;
				}
			} else {
				// this will handle YearToDate and specific year
				thisYear = dateRange.id === 'YearToDate' ? thisYear : Number(dateRange.id);
				lastYear = thisYear - 1;
				lastJan1 = new Date(lastYear, 0, 1);

				result[index].data = sensorData.data.filter((_data) => _data.timestamp.getFullYear() === thisYear);
				result[index].data = convertHourlyToAverageDailyData([...result[index].data]);
			}
		}
	});

	return result;
};

const calculateViaPropertyName = (data, key) => {
	return data.filter((d) => !isNaN(d[key])).reduce((a, b) => a + b[key], 0) / data.filter((d) => !isNaN(d[key])).length;
};

export const rollupData = (data) => {
	const params = ['Chl', 'PC', 'PH', 'DO', 'Ri', 'Turbidity', 'airTemp'];
	let value = {
		timestamp: data[0].timestamp,
	};

	params.forEach((param) => {
		value[param] = calculateViaPropertyName(data, param);
	});

	return value;
};

// in art data we have values for each day for each 30 min, So in this service we want to make 1 value for each day, which will be the average for all the hourly values
export const convertHourlyToAverageDailyData = (data) => {
	if (!data || data.length === 0) return undefined;
	let result = [];

	let i = 0,
		j = 0;
	let value, date;
	const keysToCopy = ['Chl', 'DO', 'PC', 'PH', 'Ri'];

	for (i = 0; i < data.length; i++) {
		value = data[i];

		j = i + 1;
		if (j >= data.length) {
			return result;
		}
		let counter = 1;

		date = value.timestamp;
		const isSameDate = date.getFullYear() === data[j].timestamp.getFullYear() && date.getMonth() === data[j].timestamp.getMonth() && date.getDay() === data[j].timestamp.getDay();
		let averagedValues = undefined;
		if (isSameDate) {
			//same date different hours
			averagedValues = {
				timestamp: '',

				airTemp: '',

				WaterTemp: '',

				Chl: 0,

				solarlight: '',

				PC: 0,

				sigStrength: '',

				Turbidity: '',

				// Ri: chlorA / phycocyanin,
				Ri: 0,

				battSOC: '', // batteryStatus requested, this is provided

				battVolts: '', // batteryStatus requested, this is provided

				PH: 0, // not provided

				DO: 0, // not provided

				enabled: true,

				brushError: '', //x

				codeVersion: '', //x

				fixOk: '', //x

				leakDetect: '', //x

				panelVolts: '', //x

				sigQuality: '', //x
			};
			counter = 1;
			while (data[j] && date.getFullYear() === data[j].timestamp.getFullYear() && date.getMonth() === data[j].timestamp.getMonth() && date.getDay() === data[j].timestamp.getDay()) {
				//average sum of values
				j++;
				counter++;
				if (!averagedValues['timestamp']) {
					averagedValues = { ...value };
				}

				keysToCopy.forEach((el) => {
					if (averagedValues && data[j]) {
						averagedValues[el] += data[j][el] || 0;
					}
				});
			}
			if (counter > 1) {
				// move i to the next day
				i += counter - 1;
			}

			if (averagedValues) {
				if (counter > 1) {
					keysToCopy.forEach((el) => {
						averagedValues[el] = Math.floor(averagedValues[el] / counter);
					});
					result.push(averagedValues);
				}
			}
		} else {
			//different dates
			if (averagedValues) {
				if (counter > 1) {
					keysToCopy.forEach((el) => {
						averagedValues[el] = Math.floor(averagedValues[el] / counter);
					});
					counter = 1;
					result.push(averagedValues);
				}
			} else {
				result.push(value);
			}
			averagedValues = undefined;
		}
	}
	return result;
};
